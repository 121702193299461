// Component to render Operator Search widget next to Jobs list
import { useEffect, useRef, useState } from 'react';

import { ArrowLeftEndOnRectangleIcon } from '@heroicons/react/16/solid';
import {
  OperatorSearch,
  OperatorTruckIndexRecord,
  SearchFiltersPopover,
  SearchIndexResult,
} from '@isheepdog/operator-search';
import { useList, useToggle } from '@uidotdev/usehooks';

import {
  groupBy,
  isEmpty,
  isEqual,
  omit,
  partition,
  reduce,
  some,
  uniq,
} from 'lodash';

import OperatorSearchResult from '@/components/Search/OperatorSearchResult';

import { useAuthContext } from '@/lib/context/AuthContext';
import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';

import { firestore } from '@/lib/firebase';
import { CustomTagTopic, truckMaterials } from '@/lib/firebase/db/@types';
import {
  OPERATOR_SEARCH_FACETS,
  getDefaultOperatorSearchFacetValues,
  getJobInitialSiteRefMap,
  getOperatorTruckOwnerFromEmployee,
  getUserSavedSearchFilters,
  parseOperatorTruckForOperatorSearch,
  saveUserSearchFilters,
} from '@/lib/firebase/db/helpers';
import { OperatorTruckDoc } from '@/lib/firebase/db/metaTypes';
import { customTagsByCreatorCompanyQuery } from '@/lib/firebase/db/queries';

import { algoliaConfig } from '@/lib/env';
import { isAssigner, isSupport } from '@/lib/helpers/userRoles';

export default function OperatorSearchWidget() {
  const { userCompanyId, userDoc } = useAuthContext();
  const {
    selectedJobOperatorTrucks,
    selectedJobAssignments,
    selectedJob,
    selectedGroup,
    onSelectGroup,
    onSelectOperatorToAssign,
  } = useEditSchedulerContext();
  const [
    selectedFilters,
    { set: setSelectedFilters, push: pushSelectedFilter },
  ] = useList<string>([]);
  const selectedFiltersRef = useRef<string[]>([]);
  const [customTagsByTopic, setCustomTagsByTopic] = useState<
    Partial<Record<CustomTagTopic, string[]>>
  >({});
  const [mandatoryAttributes, { set: setMandatoryAttributes }] =
    useList<string>([]);
  // When multiple operator tags are selected only show operators
  // that contain all of them
  const [conjunctiveAttributes] = useState(['operator.tags']);
  const [sortResultsByDistanceToSite, toggleSortResultsByDistanceToSite] =
    useToggle(false);

  // Load user saved search filter on mount and save them on unmount
  useEffect(() => {
    if (!userDoc) return;

    const savedSearchFilters = getUserSavedSearchFilters(userDoc);
    setSelectedFilters(savedSearchFilters);

    return () => {
      const filtersToSave = selectedFiltersRef.current.filter((filter) =>
        filter.startsWith('operator.role')
      );
      const currentFilters = getUserSavedSearchFilters(userDoc);
      if (!isEqual(currentFilters, filtersToSave) && !!filtersToSave.length) {
        saveUserSearchFilters(userDoc, filtersToSave);
      }
    };
  }, []);

  // Load default filters based on selectedJob
  useEffect(() => {
    const productMaterial = selectedJob?.get('productMaterial');
    if (
      !!productMaterial &&
      // Only apply filter if job material matches some truck material
      some(truckMaterials, (material) => material === productMaterial)
    ) {
      pushSelectedFilter(`truck.materials:"${productMaterial}"`);
    }
    const initialSite = !!selectedJob && getJobInitialSiteRefMap(selectedJob);
    toggleSortResultsByDistanceToSite(!!initialSite);
  }, [selectedJob]);

  useEffect(() => {
    // Save reference to current selected filters value to be able to access it on cleanup.
    selectedFiltersRef.current = selectedFilters;
  }, [selectedFilters]);

  // Load custom tags created by user company
  useEffect(() => {
    return firestore.onSnapshot(
      customTagsByCreatorCompanyQuery({ creatorCompanyId: userCompanyId }),
      (querySnapshot) => {
        const customTagsDocs = firestore.filterSoftDeletedDocs(
          querySnapshot.docs
        );
        const tagsDocsByTopic = groupBy(customTagsDocs, (customTagDoc) =>
          customTagDoc.get('topic')
        );
        const tagsByTopic = reduce(
          tagsDocsByTopic,
          (tagsByTopic, tagsDocs, topic) => {
            return {
              ...tagsByTopic,
              [topic]: uniq(tagsDocs.map((tagDoc) => tagDoc.get('value'))),
            };
          },
          {}
        );
        setCustomTagsByTopic(tagsByTopic);
      }
    );
  }, [userCompanyId]);

  const onSelectFilter = (filterOptionId: string, isSelected: boolean) => {
    const newSelectedFilters = isSelected
      ? selectedFilters.concat(filterOptionId)
      : selectedFilters.filter((id) => id !== filterOptionId);
    setSelectedFilters(newSelectedFilters);
  };

  const onSelectMandatoryAttribute = (
    facetAttribute: string,
    isSelected: boolean
  ) => {
    return isSelected
      ? setMandatoryAttributes(mandatoryAttributes.concat(facetAttribute))
      : setMandatoryAttributes(
          mandatoryAttributes.filter(
            (attribute) => attribute !== facetAttribute
          )
        );
  };

  if (!userDoc || !selectedJob) {
    return;
  }

  // Do not filter by reader company for support users
  const readerCompanyId = isSupport(userDoc) ? undefined : userCompanyId;

  // Split selectedJobOperatorTrucks into OperatorTruckDoc and OperatorTruckIndexRecord
  const [operatorTruckDocs, operatorTruckIndexRecords] = partition(
    selectedJobOperatorTrucks,
    (element) => 'get' in element
  ) as [OperatorTruckDoc[], OperatorTruckIndexRecord[]];

  const operatorsToExclude: SearchIndexResult[] = operatorTruckDocs
    .filter((operatorTruckDoc) => {
      const jobAssignmentDocs = selectedJobAssignments.filter(
        (jobAssignmentDoc) =>
          jobAssignmentDoc.get('receiver.user.id') ===
          operatorTruckDoc.get('operator.userId')
      );
      const isFromSameGroup = jobAssignmentDocs.some(
        (jobAssignmentDoc) =>
          jobAssignmentDoc?.get('groupIndex') === selectedGroup
      );
      // Exclude all operators from same group and any other non-assigner from other groups
      return isFromSameGroup || !isAssigner(operatorTruckDoc);
    })
    .map(parseOperatorTruckForOperatorSearch)
    .concat(operatorTruckIndexRecords);

  const initialSite = getJobInitialSiteRefMap(selectedJob);
  let centerCoordinates;
  if (sortResultsByDistanceToSite) {
    centerCoordinates = {
      lat: initialSite?.lat || 0,
      lng: initialSite?.lng || 0,
    };
  }

  const roleFilters = selectedFilters.filter((filter) =>
    filter.startsWith('operator.role')
  );
  const truckFilters = selectedFilters.filter((filter) =>
    filter.startsWith('truck')
  );
  // Check if is explicitly excluding owners from search results
  const replaceEmployeesWithOwners =
    isEmpty(roleFilters) ||
    some(roleFilters, (roleFilter) => roleFilter.includes('operatorOwner'));
  const replaceEmployeesWithBrokers =
    isEmpty(roleFilters) ||
    some(roleFilters, (roleFilter) => roleFilter.includes('broker'));
  const replaceEmployees =
    !isEmpty(truckFilters) &&
    mandatoryAttributes.includes('operator.role') &&
    (replaceEmployeesWithBrokers || replaceEmployeesWithOwners);

  return (
    <div className="operator-search-widget">
      <div className="mb-2 flex justify-between">
        {!!initialSite && (
          <label className="sort-by-distance-checkbox-label clickable label py-0">
            <input
              type="checkbox"
              checked={sortResultsByDistanceToSite}
              onClick={() => toggleSortResultsByDistanceToSite()}
              className="checkbox-secondary checkbox checkbox-xs mr-1"
            />
            <div className="text-xs">{`Show Operators closest to ${initialSite.name} first.`}</div>
          </label>
        )}
        <div
          className="btn btn-xs h-5 min-h-5 rounded-full"
          onClick={() => onSelectGroup(null)}
        >
          {'Close'}
          <ArrowLeftEndOnRectangleIcon className="size-4" />
        </div>
      </div>
      <OperatorSearch
        onSelectOperator={onSelectOperatorToAssign}
        readerCompanyId={readerCompanyId}
        userId={userDoc.id}
        algoliaAppId={algoliaConfig.appId}
        algoliaApiKey={algoliaConfig.apiKey}
        algoliaIndexName={algoliaConfig.indexNameOperatorTruck}
        autoFocus
        searchFilters={selectedFilters}
        mandatoryAttributes={mandatoryAttributes}
        conjunctiveAttributes={conjunctiveAttributes}
        hideOverlayOnClickResult={false}
        showResultsOnFocus
        excludeElements={operatorsToExclude}
        typingTimeoutMs={500}
        renderSearchResult={(props) => (
          <OperatorSearchResult key={props.key} {...omit(props, 'key')} />
        )}
        aroundCoordinates={centerCoordinates}
        resultsOverlayMaxHeight="70vh"
        alwaysRenderResults
        replaceEmployeeWithOwner={
          replaceEmployees
            ? (operatorTruck) =>
                getOperatorTruckOwnerFromEmployee(operatorTruck, {
                  broker: replaceEmployeesWithBrokers,
                  owner: replaceEmployeesWithOwners,
                })
            : undefined
        }
      >
        <SearchFiltersPopover
          selectedFilters={selectedFilters}
          onSelectFilter={onSelectFilter}
          readerCompanyId={readerCompanyId}
          algoliaAppId={algoliaConfig.appId}
          algoliaApiKey={algoliaConfig.apiKey}
          algoliaIndexName={algoliaConfig.indexNameOperatorTruck}
          facetsList={OPERATOR_SEARCH_FACETS}
          facetAvailableValues={getDefaultOperatorSearchFacetValues({
            companyTagsFacetValues: customTagsByTopic['company'],
            operatorTagsFacetValues: customTagsByTopic['user'],
            truckTagsFacetValues: customTagsByTopic['truck'],
          })}
          columns={2}
          width={500}
          mandatoryAttributes={mandatoryAttributes}
          onSelectMandatoryAttribute={onSelectMandatoryAttribute}
          onClearFilters={() => {
            setSelectedFilters([]);
            setMandatoryAttributes([]);
          }}
        />
      </OperatorSearch>
    </div>
  );
}
