// Component to display the most important Job info as a card format
import React, { MouseEvent, useRef, useState } from 'react';

import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/16/solid';
import clsx from 'clsx';

import { size } from 'lodash';

import JobGroups from '@/components/Scheduler/SchedulerMain/JobCard/JobGroups';
import JobInfo from '@/components/Scheduler/SchedulerMain/JobCard/JobInfo';

import { useAuthContext } from '@/lib/context/AuthContext';
import { useEditSchedulerContext } from '@/lib/context/EditSchedulerContext';
import { useSchedulerContext } from '@/lib/context/SchedulerContext';

import { getJobRequiresAttention } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

import { useShouldScroll } from '@/lib/helpers/hooks';
import { getCanUserEditAllJobAssignments } from '@/lib/helpers/scheduler';

export default function JobCard({ jobDoc }: { jobDoc: JobDoc }) {
  const { userDoc } = useAuthContext();
  const { showOperators } = useSchedulerContext();
  const { canUserEditDispatchSchedule, selectedJob, onSelectJob } =
    useEditSchedulerContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const doesJobRequiresAttention = getJobRequiresAttention(jobDoc);
  const isJobIncomplete = jobDoc.get('overallStatus') === 'INCOMPLETE';
  const jobGroupsContainer = useRef<HTMLDivElement | null>(null);
  const shouldScroll = useShouldScroll(jobGroupsContainer);

  const showGroups = showOperators || isExpanded;
  const groupCount = size(jobDoc.get('groupsOfOperators'));

  const isSelectedJob = selectedJob?.id === jobDoc.id;

  const isSelectable =
    !isSelectedJob &&
    canUserEditDispatchSchedule &&
    !!userDoc &&
    getCanUserEditAllJobAssignments(userDoc, jobDoc) &&
    groupCount >= 1;

  const onClickJobCard = (jobDoc: JobDoc) => (ev: MouseEvent) => {
    const clickTarget = ev.target as HTMLElement;
    // Check that user has not clicked another actionable element of JobCard
    const actionableItemsSelectors = [
      '.add-operators-link',
      '.job-title',
      '.unfold-operators-button',
      '.job-assignment-info .clickable',
      '.edit-broker-button',
      '.job-broker-company-selector',
    ];
    const hasClickedActionableItem = actionableItemsSelectors.some(
      (selector) => !!clickTarget.closest(selector)
    );
    if (!hasClickedActionableItem) {
      onSelectJob(jobDoc);
    }
  };

  return (
    <div className="job-card-container relative">
      {isSelectedJob ? (
        <div
          className="unselect-job-btn btn btn-outline btn-sm absolute right-0 top-[-2.5rem] z-[2] bg-base-100"
          onClick={() => onSelectJob(null)}
        >
          <div className="tooltip" data-tip="Unselect Job ">
            <XMarkIcon className="size-6" />
          </div>
        </div>
      ) : (
        !!selectedJob && (
          <div className="job-card-overlay absolute z-[2] h-full w-full opacity-20"></div>
        )
      )}
      <div
        className={clsx(
          'job-card card max-h-fit bg-base-100 ',
          doesJobRequiresAttention &&
            `outline outline-4 ${isJobIncomplete ? 'outline-primary' : 'outline-info'}`,
          isSelectable && 'clickable',
          !!selectedJob && !isSelectedJob
            ? 'opacity-20'
            : 'shadow-lg drop-shadow-lg'
        )}
        onClick={isSelectable ? onClickJobCard(jobDoc) : undefined}
      >
        <div
          className={clsx(
            'job-card-upper bg-accent-dark px-3 py-6 text-base-100 transition-[border-radius] duration-300',
            showGroups ? 'rounded-t-2xl' : 'rounded-2xl'
          )}
        >
          <JobInfo jobDoc={jobDoc} />
          {!showOperators && (
            <UnfoldOperatorsGroupsButton
              onClick={() => setIsExpanded(!isExpanded)}
              isExpanded={isExpanded}
            />
          )}
        </div>
        <div
          ref={jobGroupsContainer}
          className={clsx(
            'job-card-lower overflow-hidden rounded-b-2xl transition-[max-height] duration-300 ease-in-out',
            // Use any arbitrarily high value for max height to force animation when changing to 0
            showGroups ? 'max-h-96' : 'max-h-0',
            shouldScroll && 'overflow-y-scroll',
            groupCount > 1 && 'bg-accent-dark'
          )}
        >
          <JobGroups jobDoc={jobDoc} />
        </div>
      </div>
    </div>
  );
}

function UnfoldOperatorsGroupsButton({
  onClick,
  isExpanded,
}: {
  onClick: React.MouseEventHandler;
  isExpanded: boolean;
}) {
  return (
    <div className="unfold-operators-button absolute left-0 w-full opacity-0 hover:opacity-100">
      <div
        className="clickable flex items-center justify-center "
        onClick={onClick}
      >
        <div className="btn btn-ghost btn-xs btn-block rounded-xl">
          <ChevronDownIcon
            className={clsx(
              'size-6 transition duration-300',
              isExpanded && 'scale-y-[-1]'
            )}
          />
        </div>
      </div>
    </div>
  );
}
