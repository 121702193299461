// Render the Job product information and optionally the currently loaded quantity
import clsx from 'clsx';

import { useAuthContext } from '@/lib/context/AuthContext';

import { getJobProductName } from '@/lib/firebase/db/helpers';
import { JobDoc } from '@/lib/firebase/db/metaTypes';

import { FEATURES, getIsFeatureEnabled } from '@/lib/helpers/features';

export default function JobProductLabel({ jobDoc }: { jobDoc: JobDoc }) {
  const { userDoc } = useAuthContext();

  const jobProductStr = getJobProductName(jobDoc);
  if (!jobProductStr) return null;

  const unit = jobDoc.get('unit');
  let totalQuantity: string | number = jobDoc.get('totalQuantity') || 0;
  if (!!totalQuantity) {
    totalQuantity = totalQuantity.toFixed(2);
  }
  const showJobTotalQty = unit === 'tons';
  // Check if job has total requested quantity filled
  const productQuantity = jobDoc.get('productQuantity');
  const showRequestedQuantity = !!productQuantity;

  const jobQtyStr = showRequestedQuantity
    ? `${totalQuantity}/${productQuantity} ${unit}`
    : `${totalQuantity} ${unit}`;
  return (
    <div
      className={clsx(
        'job-product-label flex text-xs',
        showJobTotalQty ? 'justify-between' : 'justify-start'
      )}
    >
      <div className="product-name">{jobProductStr}</div>
      {showJobTotalQty && (
        <div className="total-quantity-label">{jobQtyStr}</div>
      )}
    </div>
  );
}
